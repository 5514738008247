import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ChainId, Config, DAppProvider } from "@usedapp/core";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import i18n from "./core/i18n";
import { GlobalStyle, theme } from "./core/theme";
import { App } from "./pages/App";
import reportWebVitals from "./reportWebVitals";

const config: Config = {
  readOnlyChainId: ChainId.BSC,
  readOnlyUrls: {
    [ChainId.Ropsten]: "https://eth-ropsten.alchemyapi.io/v2/58qN8AFEgTP96MnqoLRBRIcYri_d9YXB",
    [ChainId.Rinkeby]: "https://eth-rinkeby.alchemyapi.io/v2/es1Qzjzn5AgNC48y7-ghzrPDBH1QB4Xh",
    [ChainId.BSCTestnet]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    [ChainId.BSC]: "https://bsc-dataseed1.ninicoin.io",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ChakraProvider theme={extendTheme({ config: { initialColorMode: "dark" } })}>
            <ThemeProvider theme={theme}>
              <Fragment>
                <App />
                <GlobalStyle />
              </Fragment>
            </ThemeProvider>
          </ChakraProvider>
        </I18nextProvider>
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
