import { ChainId } from "@usedapp/core";

export enum SUPPORTED_CHAIN_ID {
  // MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  // GOERLI = 5,
  // KOVAN = 42,
}

export const SUPPORTED_CHAIN_IDS = [ChainId.BSCTestnet, ChainId.BSC] as number[];
