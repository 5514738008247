import { HamburgerIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Link, Menu, MenuButton, MenuItem, MenuList, useClipboard } from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import { ChainId, shortenAddress, useEtherBalance, useEthers } from "@usedapp/core";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { images } from "../../core/constants/images";
import { I18N_NAMESPACES } from "../../core/i18n";
import { RoundedButton } from "../RoudedButton";
import Identicon from "./Identicon";
import { TabItem } from "./TabItem";
import { Link as RouterLink } from "react-router-dom";

const Container = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: 120px 1fr 120px;
  padding: 16px;
  justify-content: space-between;
`;

const SettingContainer = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
`;

const Balance = styled.div`
  padding: 10px 16px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
`;

const AccountContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.bg2};
  border-radius: ${(props) => props.theme.borderRadius.md};
`;

const AccountAddress = styled.button`
  border: none;
  padding: 10px 16px;
  background-color: ${(props) => props.theme.colors.bg1};
  color: ${(props) => props.theme.colors.text1};
  border-radius: ${(props) => props.theme.borderRadius.md};
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TabContainer = styled.div`
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  align-items: center;
  justify-content: center;
`;

export const AppHeader = React.memo(() => {
  const { activateBrowserWallet, account, error, chainId } = useEthers();
  const etherBalance = useEtherBalance(account);
  const { t } = useTranslation([I18N_NAMESPACES.HEADER]);
  const { hasCopied, onCopy } = useClipboard(account || "");
  const themeContext = useContext(ThemeContext);

  const tabItems = [
    {
      label: t("Markets"),
      route: "/",
    },
    {
      label: t("Dashboard"),
      route: "/dashboard",
    },
  ];

  const socialLinks = useMemo(
    () => [
      { name: "Twitter", logo: images.twitter, link: "https://twitter.com/bePAY_BNPL" },
      { name: "Telegram", logo: images.telegram, link: "https://t.me/bePayglobal" },
      { name: "Medium", logo: images.news, link: "https://medium.com/bepay-news" },
      { name: "YouTube", logo: images.youtube, link: "https://www.youtube.com/channel/UC8NVWQCELIJaMLYuNo_6xDg" },
      { name: "Facebook", logo: images.facebook, link: "https://www.facebook.com/bepaybnpl/" },
      { name: "Email", logo: images.mail, link: "mailto:contact@bepay.finance" },
    ],
    []
  );

  useEffect(() => {
    if (error?.name === "NoEthereumProviderError") {
      window.open("https://metamask.io/download.html");
    }
  }, [error]);

  return (
    <Container>
      <RouterLink to="/">
        <img src={images.logo} />
      </RouterLink>

      <TabContainer>
        {tabItems.map((item) => (
          <TabItem label={item.label} route={item.route} key={item.route} />
        ))}
      </TabContainer>

      <SettingContainer>
        {account ? (
          <>
            <AccountContainer>
              {etherBalance && (
                <Balance>
                  {parseFloat(formatEther(etherBalance)).toFixed(3)}{" "}
                  {chainId && [ChainId.BSC, ChainId.BSCTestnet].includes(chainId) ? "BNB" : "ETH"}
                </Balance>
              )}
              <AccountAddress onClick={onCopy}>
                {hasCopied ? "Copied" : shortenAddress(account)}
                <Identicon />
              </AccountAddress>
            </AccountContainer>
          </>
        ) : (
          <RoundedButton onClick={() => activateBrowserWallet()}>{t("Connect Wallet")}</RoundedButton>
        )}
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            background={themeContext.colors.bg1}
            color="#fff"
            marginLeft={"14px"}
            borderRadius={themeContext.borderRadius.md}
            height={"44px"}
            width={"44px"}
            _hover={{
              background: themeContext.colors.bg2,
            }}
            _active={{
              background: themeContext.colors.bg2,
            }}
          />
          <MenuList background={themeContext.colors.bg1} borderColor={themeContext.colors.bg1}>
            {socialLinks.map((i) => (
              <Link href={i.link} target="_blank">
                <MenuItem
                  icon={
                    <Flex height="30px" width="30px" alignItems="center" justifyContent="center">
                      <img src={i.logo} />
                    </Flex>
                  }
                >
                  {i.name}
                </MenuItem>
              </Link>
            ))}
          </MenuList>
        </Menu>
      </SettingContainer>
    </Container>
  );
});
