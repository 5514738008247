import { useEthers, useTokenBalance } from "@usedapp/core";
import { utils } from "ethers";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SupplyModal } from "../../../components/SupplyModal";
import { TokenIcon } from "../../../components/TokenIcon";
import { useDecimals, useTokenSymbol } from "../../../core/hooks/token";
import { IToken } from "../../../core/models/token";
import { formatNumber } from "../../../core/utils/formatNumber";

interface Props {
  token: IToken;
}

const Row = styled.tr`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.bg2};
  }
`;

const Cell = styled.td<{ align?: string }>`
  color: ${(props) => props.theme.colors.text1};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

const AssetRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AvailableSupplyingRow = React.memo((props: Props) => {
  const { account } = useEthers();
  const balance = useTokenBalance(props.token.underlying, account);

  const [isOpenSupplyModal, setIsOpenSupplyModal] = useState(false);

  const walletBalance = useMemo(() => {
    if (!balance || !props.token.decimals) return 0;
    return +utils.formatUnits(balance, props.token.decimals);
  }, [balance, props.token.decimals]);

  return (
    <Row onClick={() => setIsOpenSupplyModal(true)}>
      <Cell>
        <AssetRow>
          <TokenIcon url={props.token.logoUrl} />
          <span>{props.token.symbol}</span>
        </AssetRow>
      </Cell>
      <Cell align="right">{formatNumber(walletBalance)}</Cell>

      <SupplyModal
        token={props.token}
        isOpen={isOpenSupplyModal}
        onDismiss={(e) => {
          e.stopPropagation();
          setIsOpenSupplyModal(false);
        }}
      />
    </Row>
  );
});
