import usdt from "../../assets/images/usdt.svg";
import usdc from "../../assets/images/usdc.svg";
import dai from "../../assets/images/dai.svg";
import bnb from "../../assets/images/bnb.svg";
import tokenPlaceholder from "../../assets/images/token-placeholder.svg";
import closeFilled from "../../assets/images/ic-close-filled.svg";
import logo from "../../assets/images/logo.svg";
import twitter from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import news from "../../assets/images/news.svg";
import youtube from "../../assets/images/youtube.svg";
import facebook from "../../assets/images/facebook.svg";
import mail from "../../assets/images/mail.svg";

export const images = {
  usdt,
  usdc,
  dai,
  closeFilled,
  tokenPlaceholder,
  logo,
  twitter,
  telegram,
  news,
  youtube,
  facebook,
  mail,
  bnb,
};
