import { CopyIcon } from "@chakra-ui/icons";
import { useClipboard } from "@chakra-ui/react";
import { Interface } from "@ethersproject/abi";
import {
  ERC20Interface,
  getExplorerTransactionLink,
  shortenAddress,
  useContractFunction,
  useEtherBalance,
  useEthers,
  useTokenAllowance,
  useTokenBalance,
} from "@usedapp/core";
import { utils } from "ethers";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { abi as BErc20Abi } from "../../abis/BErc20.json";
import { abi as BEtherAbi } from "../../abis/BEther.json";
import { BIG_NUMBER_MAX_VALUE } from "../../core/constants/bigNumber";
import { images } from "../../core/constants/images";
import {
  useApy,
  useBTokenUnderlyingBalance,
  useDecimals,
  useExchangeRate,
  useMarketSize,
} from "../../core/hooks/token";
import { IToken } from "../../core/models/token";
import { formatNumber } from "../../core/utils/formatNumber";
import { getContract } from "../../core/utils/getContract";
import { isFloat, toPlainString } from "../../core/utils/number";
import { AppModal } from "../Modal";
import { RoundedButton } from "../RoudedButton";
import { TokenIcon } from "../TokenIcon";
import { TransactionLoading } from "./TransactionLoading";
import { formatEther } from "@ethersproject/units";

interface Props {
  isOpen: boolean;
  onDismiss: (e: React.MouseEvent | React.KeyboardEvent) => void;
  token: IToken;
}

enum ACTION {
  SUPPLY,
  WITHDRAW,
}

const Header = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider0};
  padding: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: #fff;
  font-size: 20px;
  text-align: center;
`;

const CloseIcon = styled.img`
  align-self: center;
  justify-self: flex-end;
`;

const Content = styled.div`
  padding: 0px 40px;
`;

const AmountContainer = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider0};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const AmountInput = styled.input`
  background-color: transparent;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  outline: none;
  width: 100%;
  flex: 1;
`;

const MaxAmount = styled.div`
  color: ${(props) => props.theme.colors.text2};
  cursor: pointer;
`;

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0px;
  border: 1px solid #ebcef1;
  border-radius: 100px;
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text1)};
  text-align: center;
  border-radius: 100px;
  background-color: ${(props) => (props.active ? "#EBCEF1" : "transparent")};
  padding: 10px 0px;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfomartionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.text1};
`;

const RowTitle = styled.span`
  color: ${(props) => props.theme.colors.text2};
`;

const RowValue = styled.span<{ cursor?: string }>`
  color: ${(props) => props.theme.colors.text1};
  font-weight: 500;
  cursor: ${(props) => props.cursor};
`;

const SubmitBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.divider0};
  padding: 24px 0px;
`;

const ViewOnExplorerText = styled.div`
  color: rgb(33, 114, 229);
  /* color: ${(props) => props.theme.colors.bg2} */
  font-weight: 500;
`;

const AmountInputContainer = styled.div`
  display: flex;
  /* grid-template-columns: 50px 1fr 50px; */
  align-items: center;
`;

const ErrorText = styled.span`
  color: #f56565;
  font-size: 12px;
  font-weight: 500;
`;

export const SupplyModal = React.memo((props: Props) => {
  const [action, setAction] = useState(ACTION.SUPPLY);
  const { account, chainId, library } = useEthers();
  const etherBalance = useEtherBalance(account);

  const bERC20Contract = getContract(props.token.address, new Interface(BErc20Abi), library, account);
  const bEtherContract = getContract(props.token.address, new Interface(BEtherAbi), library, account);
  const balance = useTokenBalance(props.token.address, account);
  const decimals = useDecimals(props.token.address);
  const exchangeRate = useExchangeRate(props.token.address);
  const marketSize = useMarketSize(props.token.address);
  const formattedMarketSize = useMemo(() => {
    if (!marketSize || !decimals) return 0;
    return +marketSize.toString() / 10 ** decimals;
  }, [marketSize, decimals]);

  const supplyApy = useApy(props.token.address);

  const underlyingDecimals = useMemo(() => props.token.decimals, [props.token.decimals]);
  const allowance = useTokenAllowance(props.token.underlying, account, props.token.address);
  const formattedAllowance = useMemo(
    () =>
      props.token.isNative
        ? Number.MAX_SAFE_INTEGER
        : allowance
        ? +utils.formatUnits(allowance, underlyingDecimals)
        : 0,
    [allowance, props.token.isNative]
  );
  const underlyingContract =
    !props.token.isNative && props.token.underlying ? getContract(props.token.underlying, ERC20Interface) : null;
  const underlyingBalance = useTokenBalance(props.token.underlying, account);

  const bTokenUnderlyingBalance = useBTokenUnderlyingBalance(props.token.address);
  const supplyBalance = useMemo(() => {
    if (!bTokenUnderlyingBalance || !underlyingDecimals) return 0;

    return +utils.formatUnits(bTokenUnderlyingBalance, underlyingDecimals);
  }, [bTokenUnderlyingBalance, underlyingDecimals]);

  const walletBalance = useMemo(() => {
    return props.token.isNative && etherBalance
      ? parseFloat(formatEther(etherBalance))
      : !!underlyingBalance && !!underlyingDecimals
      ? +utils.formatUnits(underlyingBalance, underlyingDecimals)
      : 0;
  }, [underlyingBalance, underlyingDecimals, etherBalance, props.token.isNative]);

  const approveFunction = useContractFunction(underlyingContract as any, "approve");
  const bErc20DepositFunction = useContractFunction(bERC20Contract, "deposit");
  const bEtherDepositFunction = useContractFunction(bEtherContract, "deposit");
  const withdrawFunction = useContractFunction(bERC20Contract, "redeem");

  const [isLoading, setIsLoading] = useState(false);
  const [isPressMaxBtn, setIsPressMaxBtn] = useState(false);

  const validationSchema = yup.object({
    amount: yup
      .string()
      .min(0, "Price must greater than 0")
      .max(Number.MAX_SAFE_INTEGER, "Price must be a safe number")
      .test(
        "length",
        (_) => "Price must be a safe number",
        (currentValue) => {
          if (!currentValue || isPressMaxBtn) return true;
          return currentValue.toString().replace(".", "").length < 17;
        }
      )
      .test({
        name: "decimal",
        params: { decimals },
        message: "Decimal places must be less than or equal to ${decimals} numbers",
        test: (currentValue, context) => {
          if (!currentValue || isPressMaxBtn) return true;
          const [_, fraction] = toPlainString(currentValue)?.split(".");
          return !fraction || fraction.length <= decimals;
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      amount: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      if (!values.amount) return;

      if (action === ACTION.SUPPLY) {
        onDeposit(values.amount);
      } else if (action === ACTION.WITHDRAW) {
        onWithdraw(values.amount);
      }
    },
  });

  const maxWidthdrawAmount = useMemo(
    () => Math.min(formattedMarketSize, supplyBalance),
    [formattedMarketSize, supplyBalance]
  );

  const { hasCopied: hasCopiedTokenAddress, onCopy: onCopyTokenAddress } = useClipboard(props.token.underlying || "");

  const depositTransactionStatus = useMemo(
    () => (props.token.isNative ? bEtherDepositFunction.state : bErc20DepositFunction.state),
    [bErc20DepositFunction.state, bEtherDepositFunction.state]
  );

  //Set loading
  useEffect(() => {
    if (
      approveFunction.state.status === "Mining" ||
      depositTransactionStatus.status === "Mining" ||
      withdrawFunction.state.status === "Mining"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [approveFunction.state, depositTransactionStatus, withdrawFunction.state]);

  //Show toast if success or error
  useEffect(() => {
    if (depositTransactionStatus.errorMessage) {
      toast.error(depositTransactionStatus.errorMessage.replace("execution reverted: ", ""), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (depositTransactionStatus.status === "Success") {
      toast.success(
        <div>
          <div>
            Supply {formatNumber(+(formik.values.amount || "0"))} {props.token.symbol}
          </div>
          {chainId && depositTransactionStatus.transaction?.hash && (
            <a href={getExplorerTransactionLink(depositTransactionStatus.transaction?.hash, chainId)} target={"_blank"}>
              <ViewOnExplorerText>View on Explorer</ViewOnExplorerText>
            </a>
          )}
        </div>,
        {
          position: toast.POSITION.TOP_RIGHT,
          onOpen: () => {
            formik.setFieldValue("amount", undefined);
          },
          autoClose: 15000,
        }
      );
    }
  }, [depositTransactionStatus]);

  useEffect(() => {
    if (withdrawFunction.state.errorMessage) {
      toast.error(withdrawFunction.state.errorMessage.replace("execution reverted: ", ""), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (withdrawFunction.state.status === "Success") {
      toast.success(
        <div>
          <div>
            Withdraw {formatNumber(+(formik.values.amount || "0"))} {props.token.symbol}
          </div>
          {chainId && withdrawFunction.state.transaction?.hash && (
            <a href={getExplorerTransactionLink(withdrawFunction.state.transaction?.hash, chainId)} target={"_blank"}>
              <ViewOnExplorerText>View on Explorer</ViewOnExplorerText>
            </a>
          )}
        </div>,
        {
          position: toast.POSITION.TOP_RIGHT,
          onOpen: () => {
            formik.setFieldValue("amount", undefined);
          },
          autoClose: 15000,
        }
      );
    }
  }, [withdrawFunction.state]);

  const onChangeSupplyTab = useCallback(() => {
    setAction(ACTION.SUPPLY);
    formik.setFieldValue("amount", undefined);
  }, []);

  const onChangeWithdrawTab = useCallback(() => {
    setAction(ACTION.WITHDRAW);
    formik.setFieldValue("amount", undefined);
  }, []);

  const onEnable = useCallback(() => {
    if (!account) {
      toast.error("You need to connect wallet first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    approveFunction.send(props.token.address, BIG_NUMBER_MAX_VALUE);
  }, [approveFunction, underlyingBalance, account]);

  const onDeposit = useCallback(
    async (amount: string) => {
      if (!amount) return;

      if (+amount > formattedAllowance) {
        toast.error("The deposited amount is more than the allowance amount.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const maxAmount = Math.min(formattedAllowance, walletBalance);

      if (+amount >= maxAmount) {
        if (props.token.isNative) {
          const fee = await library?.getFeeData();
          const gasPrice = fee?.gasPrice?.toNumber() || 0;

          const functionGasLimit = await bEtherContract.estimateGas.deposit({
            value: utils.parseEther(toPlainString(maxAmount - gasPrice / 1e18)),
          });
          const estimateFunctionGas = (functionGasLimit.toNumber() * (fee?.gasPrice?.toNumber() || 1)) / 1e18;

          bEtherDepositFunction.send({
            value: utils.parseEther(toPlainString(maxAmount - estimateFunctionGas - gasPrice / 1e18)),
          });
        } else {
          bErc20DepositFunction.send(BigInt(Math.floor(maxAmount * Math.pow(10, underlyingDecimals))));
        }
      } else {
        if (props.token.isNative) {
          bEtherDepositFunction.send({
            value: utils.parseEther(toPlainString(amount)),
          });
        } else {
          bErc20DepositFunction.send(BigInt(Math.floor(+amount * Math.pow(10, underlyingDecimals))));
        }
      }
    },
    [bErc20DepositFunction]
  );

  const onWithdraw = useCallback(
    (amount: string) => {
      if (!amount) return;

      if (+amount >= supplyBalance) {
        withdrawFunction.send(bTokenUnderlyingBalance);
      } else {
        withdrawFunction.send(BigInt(Math.floor(+amount * Math.pow(10, underlyingDecimals))));
      }
    },
    [withdrawFunction]
  );

  const onSupplyAllToken = () => {
    formik.setFieldValue(
      "amount",
      action === ACTION.SUPPLY ? Math.min(formattedAllowance, walletBalance).toString() : supplyBalance.toString()
    );
    setIsPressMaxBtn(true);
  };

  return (
    <AppModal isOpen={props.isOpen} onDismiss={props.onDismiss} width={500}>
      {isLoading ? (
        <TransactionLoading />
      ) : (
        <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
          <Header>
            <div />
            <TitleContainer>
              <TokenIcon url={props.token.logoUrl} size={35} />
              <Title>{props.token.symbol}</Title>
            </TitleContainer>
            <CloseIcon src={images.closeFilled} onClick={props.onDismiss} />
          </Header>

          <Content>
            <AmountContainer>
              <AmountInputContainer>
                <div style={{ opacity: 0 }}>Max</div>
                <AmountInput
                  placeholder="0"
                  value={
                    !!formik.values.amount
                      ? `${isPressMaxBtn ? "~" : ""} ${
                          isPressMaxBtn ? (+formik.values.amount).toFixed(6) : formik.values.amount
                        }`.trim()
                      : ""
                  }
                  // value={amount || ""}
                  // type="number"
                  id="amount"
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    setIsPressMaxBtn(false);
                  }}
                />
                <MaxAmount onClick={onSupplyAllToken}>Max</MaxAmount>
              </AmountInputContainer>
              {!!formik.errors.amount && !!formik.touched.amount && <ErrorText>{formik.errors.amount}</ErrorText>}
            </AmountContainer>

            <TabContainer>
              <TabItem active={action === ACTION.SUPPLY} onClick={onChangeSupplyTab}>
                Supply
              </TabItem>
              <TabItem active={action === ACTION.WITHDRAW} onClick={onChangeWithdrawTab}>
                Withdraw
              </TabItem>
            </TabContainer>

            <InformationContainer>
              <InfomartionRow>
                <RowTitle>Supply APY</RowTitle>
                <RowValue>{formatNumber(supplyApy * 100)}%</RowValue>
              </InfomartionRow>

              {action === ACTION.WITHDRAW && !!balance && !!exchangeRate && (
                <InfomartionRow>
                  <RowTitle>Current Supply Balance</RowTitle>
                  <RowValue>
                    ~{isFloat(supplyBalance) ? supplyBalance.toFixed(6) : supplyBalance.toString()} {props.token.symbol}
                  </RowValue>
                </InfomartionRow>
              )}
            </InformationContainer>

            <SubmitBtnContainer>
              {+formattedAllowance === 0 ? (
                <RoundedButton onClick={onEnable}>Enable</RoundedButton>
              ) : action === ACTION.SUPPLY ? (
                <RoundedButton
                  type="submit"
                  // onClick={onDeposit}
                  disabled={
                    (Number.isNaN(parseFloat(formik.values.amount || "0")) ||
                      parseFloat(formik.values.amount || "0") == 0 ||
                      parseFloat(formik.values.amount || "0") > walletBalance) &&
                    !isPressMaxBtn
                  }
                >
                  Supply
                </RoundedButton>
              ) : (
                <RoundedButton
                  type="submit"
                  // onClick={onWithdraw}
                  disabled={
                    (Number.isNaN(parseFloat(formik.values.amount || "0")) ||
                      parseFloat(formik.values.amount || "0") == 0 ||
                      parseFloat(formik.values.amount || "0") > maxWidthdrawAmount) &&
                    !isPressMaxBtn
                  }
                >
                  Withdraw
                </RoundedButton>
              )}
            </SubmitBtnContainer>

            <Footer>
              <InformationContainer>
                <InfomartionRow>
                  <RowTitle>Wallet Balance</RowTitle>
                  <RowValue>
                    {walletBalance} {props.token.symbol}
                  </RowValue>
                </InfomartionRow>
                {!!props.token.underlying && (
                  <InfomartionRow>
                    <RowTitle>Token Address</RowTitle>
                    <RowValue onClick={onCopyTokenAddress} cursor="pointer">
                      {hasCopiedTokenAddress ? (
                        "Copied"
                      ) : (
                        <>
                          {!!props.token.underlying && shortenAddress(props.token.underlying)}
                          <CopyIcon marginLeft={"8px"} />
                        </>
                      )}
                    </RowValue>
                  </InfomartionRow>
                )}
              </InformationContainer>
            </Footer>
          </Content>
        </form>
      )}
    </AppModal>
  );
});
