import { Interface } from "@ethersproject/abi";
import { ChainId, useContractCalls, useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { get } from "lodash-es";
import { useMemo } from "react";
import { abi as bErc20Abi } from "../../abis/BErc20.json";
import { abi as marketPlaceAbi } from "../../abis/Marketplace.json";
import { env } from "../environment";
import { IToken } from "../models/token";

const MARKET_PLACE_INTERFACE = new Interface(marketPlaceAbi);

const BERC20_INTERFACE = new Interface(bErc20Abi);

export const useTokenList = (): IToken[] => {
  // const marketPlaceAddress = useMarketPlaceAddress();

  // const [result] =
  // useContractCall(
  //   marketPlaceAddress && {
  //     abi: MARKET_PLACE_INTERFACE,
  //     address: marketPlaceAddress,
  //     method: "getAllMarkets",
  //     args: [],
  //   }
  // ) ?? [];

  const { chainId } = useEthers();
  const bscTestnetNetwork = get(env.network, [ChainId.BSCTestnet]);
  const bscNetwork = get(env.network, [ChainId.BSC]);

  const marketByNetwork: { [key: number]: any[] } = {
    [ChainId.BSCTestnet]: [
      {
        address: bscTestnetNetwork.btokens.berc20.instances.usdc.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2Fusdc.svg?alt=media&token=13a8e0e2-7f2e-4781-9301-5aea42424c0f",
        symbol: "USDC",
        underlying: bscTestnetNetwork.btokens.berc20.instances.usdc.underlying,
        decimals: bscTestnetNetwork.btokens.berc20.instances.usdc.decimals,
        isNative: false,
      },
      {
        address: bscTestnetNetwork.btokens.berc20.instances.usdt.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2Fusdt.svg?alt=media&token=4fed8f73-d974-43a9-b5f6-619ccda50501",
        symbol: "USDT",
        underlying: bscTestnetNetwork.btokens.berc20.instances.usdt.underlying,
        decimals: bscTestnetNetwork.btokens.berc20.instances.usdt.decimals,
        isNative: false,
      },
      {
        address: bscTestnetNetwork.btokens.bether.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2FBinance-Coin-BNB-icon.png?alt=media&token=bec28832-f5e8-46ed-8c76-e02f5702147c",
        symbol: "BNB",
        underlying: null,
        decimals: bscTestnetNetwork.btokens.bether.decimals,
        isNative: true,
      },
      {
        address: bscTestnetNetwork.btokens.berc20.instances.busd.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fbusd.png?alt=media&token=8d3c172f-cfdd-4162-9a3f-e8f5cf59a17c",
        symbol: "BUSD",
        underlying: bscTestnetNetwork.btokens.berc20.instances.busd.underlying,
        decimals: bscTestnetNetwork.btokens.berc20.instances.busd.decimals,
        isNative: false,
      },
      {
        address: bscTestnetNetwork.btokens.berc20.instances.wbnb.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fwbnb.png?alt=media&token=9d952ee9-08c1-4315-9446-7b3fd9f834f5",
        symbol: "WBNB",
        underlying: bscTestnetNetwork.btokens.berc20.instances.wbnb.underlying,
        decimals: bscTestnetNetwork.btokens.berc20.instances.wbnb.decimals,
        isNative: false,
      },
    ],

    [ChainId.BSC]: [
      {
        address: bscNetwork.btokens.berc20.instances.usdc.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2Fusdc.svg?alt=media&token=13a8e0e2-7f2e-4781-9301-5aea42424c0f",
        symbol: "USDC",
        underlying: bscNetwork.btokens.berc20.instances.usdc.underlying,
        decimals: bscNetwork.btokens.berc20.instances.usdc.decimals,
        isNative: false,
      },
      {
        address: bscNetwork.btokens.berc20.instances.usdt.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2Fusdt.svg?alt=media&token=4fed8f73-d974-43a9-b5f6-619ccda50501",
        symbol: "USDT",
        underlying: bscNetwork.btokens.berc20.instances.usdt.underlying,
        decimals: bscNetwork.btokens.berc20.instances.usdt.decimals,
        isNative: false,
      },
      {
        address: bscNetwork.btokens.bether.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/tokens%2FBinance-Coin-BNB-icon.png?alt=media&token=bec28832-f5e8-46ed-8c76-e02f5702147c",
        symbol: "BNB",
        underlying: null,
        decimals: bscNetwork.btokens.bether.decimals,
        isNative: true,
      },
      {
        address: bscNetwork.btokens.berc20.instances.busd.address,
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fbusd.png?alt=media&token=8d3c172f-cfdd-4162-9a3f-e8f5cf59a17c",
        symbol: "BUSD",
        underlying: bscNetwork.btokens.berc20.instances.busd.underlying,
        decimals: bscNetwork.btokens.berc20.instances.busd.decimals,
        isNative: false,
      },
      // {
      //   address: bscNetwork.btokens.berc20.instances.wbnb.address,
      //   logoUrl:
      //     "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fwbnb.png?alt=media&token=9d952ee9-08c1-4315-9446-7b3fd9f834f5",
      //   symbol: "WBNB",
      //   underlying: bscNetwork.btokens.berc20.instances.wbnb.underlying,
      //   decimals: bscNetwork.btokens.berc20.instances.wbnb.decimals,
      //   isNative: false,
      // },
    ],
  };

  return useMemo(() => {
    return marketByNetwork[chainId || ChainId.Rinkeby];
  }, [chainId]);
};

export const useSuppliedAddresses = () => {
  const { account } = useEthers();
  const marketList = useTokenList();

  const balances =
    useContractCalls(
      marketList.map(
        (item) =>
          item.address && {
            abi: BERC20_INTERFACE,
            address: item.address,
            method: "balanceOf",
            args: [account],
          }
      )
    ) ?? [];

  const decimalsList =
    useContractCalls(
      marketList.map(
        (item) =>
          item.address && {
            abi: BERC20_INTERFACE,
            address: item.address,
            method: "decimals",
            args: [],
          }
      )
    ) ?? [];

  return marketList.filter((i, index) => {
    const [balance] = balances[index] || [];
    const [decimals] = decimalsList[index] || [];
    return balance && decimals ? +utils.formatUnits(balance, decimals) : 0 > 0;
  });
};
