import { Interface } from "@ethersproject/abi";
import { Contract } from "@ethersproject/contracts";
import { ERC20Interface, useContractCall, useContractFunction, useEthers } from "@usedapp/core";
import { useMemo } from "react";
import { abi } from "../../abis/BErc20.json";

const BERC20_INTERFACE = new Interface(abi);

export const useUnderlyingAddress = (address: string | undefined) => {
  const underlyingAddress = useContractCall(
    address && {
      abi: BERC20_INTERFACE,
      address: address,
      method: "underlying",
      args: [],
    }
  );

  return useMemo(() => underlyingAddress || [], [underlyingAddress]);
};

export const useExchangeRate = (address: string | undefined) => {
  const [exchangeRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getExchangeRate",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return exchangeRate;
  }, [exchangeRate]);
};

export const useDecimals = (address: string | undefined | null) => {
  const [decimals] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "decimals",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return decimals;
  }, [decimals]);
};

export const useTotalBorrows = (address: string | undefined | null) => {
  const [totalBorrows] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "totalBorrows",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return totalBorrows;
  }, [totalBorrows]);
};

export const useSupplyRate = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getSupplyRate",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useTokenSymbol = (address: string | undefined): string => {
  const [symbol] =
    useContractCall(
      address && {
        abi: ERC20Interface,
        address: address,
        method: "symbol",
        args: [],
      }
    ) ?? [];

  return useMemo(() => symbol, [symbol]);
};

export const useDeposit = (contract: Contract) => {
  const contractFunction = useContractFunction(contract, "deposit", { transactionName: "Deposit" });
  return useMemo(() => contractFunction, [contractFunction]);
};

export const useBTokenUnderlyingBalance = (address: string | null | undefined) => {
  const { account } = useEthers();
  const [balance] =
    useContractCall(
      address &&
        account && {
          abi: BERC20_INTERFACE,
          address: address,
          method: "balanceOfUnderlying",
          args: [account],
        }
    ) ?? [];

  return useMemo(() => balance, [balance]);
};

export const useTotalReserves = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "totalReserves",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useTotalLenderReturn = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "currentLenderReturn",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useRemainingBorrows = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "remainingBorrows",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useMarketCash = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getCash",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useApy = (address: string | null | undefined): number => {
  const totalReserves = useTotalReserves(address);
  const totalLenderReturn = useTotalLenderReturn(address);
  const remainingBorrows = useRemainingBorrows(address);
  const marketCash = useMarketCash(address);

  return useMemo(() => {
    if (!totalReserves || !remainingBorrows || !marketCash || !totalLenderReturn) return 0;
    const parsedTotalReserves = +totalReserves?.toString();
    const parsedTotalLenderReturn = +totalLenderReturn?.toString();
    const parsedRemainingBorrors = +remainingBorrows?.toString();
    const parsedMarketCash = +marketCash?.toString();

    if (parsedMarketCash + parsedRemainingBorrors - parsedTotalReserves === 0) return 0;
    const supplyRate = parsedTotalLenderReturn / (parsedMarketCash + parsedRemainingBorrors - parsedTotalReserves);

    return Math.pow(1 + supplyRate, 365 / 42) - 1;
  }, [totalReserves, remainingBorrows, marketCash, totalLenderReturn]);
};

export const useMarketSize = (address: string | undefined | null) => {
  const marketCash = useMarketCash(address);
  const totalReserves = useTotalReserves(address);

  return useMemo(() => {
    if (!totalReserves || !marketCash) return 0;
    const parsedTotalReserves = +totalReserves?.toString();
    const parsedMarketCash = +marketCash?.toString();

    return parsedMarketCash - parsedTotalReserves;
  }, [marketCash, totalReserves]);
};
