import React from "react";
import styled from "styled-components";

const Container = styled.button`
  border: none;
  background-image: linear-gradient(
    90deg,
    rgba(167, 3, 178, 1) 0%,
    rgba(60, 52, 224, 1) 44%,
    rgba(1, 187, 246, 1) 100%
  );
  /* background-image: linear-gradient(to right, rgb(255, 0, 184) 0%, rgb(100, 161, 255) 50%, rgb(0, 102, 255) 100%); */
  border-radius: 100px;
  padding: 14px 40px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  box-shadow: 0 15px 30px rgba(0, 144, 255, 0.3);
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  transition: all 0.2s ease-in-out 0s;
  background-size: 150%;
  /* background-color: 'transparent' */

  &:active {
    transform: scale(0.93);
  }

  &:hover:enabled {
    background-image: linear-gradient(
      90deg,
      rgba(167, 3, 178, 1) 0%,
      rgba(60, 52, 224, 1) 44%,
      rgba(1, 187, 246, 1) 100%
    );
    background-position: right center;
    color: rgb(255, 255, 255);
  }
`;

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
}

export const RoundedButton = React.memo((props: React.PropsWithChildren<Props>) => {
  return (
    <Container type={props.type || "button"} disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </Container>
  );
});
