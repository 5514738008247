import { useEthers, useTokenBalance } from "@usedapp/core";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  useApy,
  useDecimals,
  useExchangeRate,
  useSupplyRate,
  useTokenSymbol,
  useBTokenUnderlyingBalance,
} from "../../../core/hooks/token";
import { utils } from "ethers";
import { TokenIcon } from "../../../components/TokenIcon";
import { formatNumber } from "../../../core/utils/formatNumber";
import { BLOCK_PER_YEAR } from "../../../core/constants/block";
import { SupplyModal } from "../../../components/SupplyModal";
import { IToken } from "../../../core/models/token";

interface Props {
  token: IToken;
}

const Row = styled.tr`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.bg2};
  }
`;

const Cell = styled.td<{ align?: string }>`
  color: ${(props) => props.theme.colors.text1};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

const AssetRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BalanceText = styled.div<{ isProfit: boolean }>`
  color: ${(props) => (props.isProfit ? "#00FFCB" : "#FF4747")};
`;

const ApyText = styled.div<{ color?: string | null }>`
  color: ${(props) => props.color || props.theme.colors.text1};
`;

export const SuppliedRow = React.memo((props: Props) => {
  const { account } = useEthers();
  const bTokenUnderlyingBalance = useBTokenUnderlyingBalance(props.token.address);

  const apy = useApy(props.token.address);

  const [isOpenSupplyModal, setIsOpenSupplyModal] = useState(false);

  const supplyBalance = useMemo(() => {
    if (!bTokenUnderlyingBalance || !props.token.decimals) return 0;

    return +utils.formatUnits(bTokenUnderlyingBalance, props.token.decimals);
  }, [bTokenUnderlyingBalance, props.token.decimals]);

  return (
    <Row onClick={() => setIsOpenSupplyModal(true)}>
      <Cell>
        <AssetRow>
          <TokenIcon url={props.token.logoUrl} />
          <span>{props.token.symbol}</span>
        </AssetRow>
      </Cell>
      <Cell align="right">
        <ApyText color={apy > 0 ? "#00FFCB" : undefined}>{formatNumber(apy * 100, 3)}%</ApyText>
      </Cell>
      <Cell align="right">
        <BalanceText isProfit={apy >= 0}>{formatNumber(supplyBalance, 3)}</BalanceText>
      </Cell>
      <SupplyModal
        token={props.token}
        isOpen={isOpenSupplyModal}
        onDismiss={(e) => {
          e.stopPropagation();
          setIsOpenSupplyModal(false);
        }}
      />
    </Row>
  );
});
