import React from "react";
import styled from "styled-components";
import { images } from "../core/constants/images";

interface Props {
  url: string;
  size?: number;
}

const Icon = styled.img<{ size?: number; backgroundColor?: string }>`
  height: ${(props) => (props.size ? `${props.size}px` : "50px")};
  width: ${(props) => (props.size ? `${props.size}px` : "50px")};
  object-fit: contain;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 100%;
`;

export const TokenIcon = React.memo((props: Props) => {
  return <Icon src={props.url} size={props.size} backgroundColor={props.url ? "transprent" : "#fff"} />;
});
